<template>
  <ion-page>
    <ion-content>
      <ion-title align="center">{{ $t("impressum.title") }}</ion-title>
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col size-xl="6" size-lg="10" size-md="12" class="ion-no-margin ion-no-padding">
            <ion-list>
              <ion-item>
                <ion-label>{{ $t("impressum.address") }}</ion-label>
                <ion-label>
                  LGtron GmbH<br>
                  Eichenlaubweg 4/2<br>
                  75181 Pforzheim<br>
                  Deutschland
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label>{{ $t("impressum.phone") }}</ion-label>
                <ion-label>+49 7231 775 4424</ion-label>
              </ion-item>
              <ion-item>
                <ion-label>{{ $t("impressum.fax") }}</ion-label>
                <ion-label>+49 7231 607 2891</ion-label>
              </ion-item>
              <ion-item>
                <ion-label>{{ $t("impressum.gericht") }}</ion-label>
                <ion-label>Amtsgericht Mannheim</ion-label>
              </ion-item>
              <ion-item>
                <ion-label>{{ $t("impressum.register") }}</ion-label>
                <ion-label>HRB 718280</ion-label>
              </ion-item>
              <ion-item>
                <ion-label>{{ $t("impressum.tax") }}</ion-label>
                <ion-label>USt-IdNr.: DE 287900647</ion-label>
              </ion-item>
              <ion-item>
                <ion-label>{{ $t("impressum.ceo") }}</ion-label>
                <ion-label>Xinhong Liu</ion-label>
              </ion-item>
              <ion-item>
                <ion-label>{{ $t("impressum.email") }}</ion-label>
                <ion-label><a href="mailto:freeze-dried-food@lgtron.com">freeze-dried-food@lgtron.com</a></ion-label>
              </ion-item>
            </ion-list>

          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import {IonPage, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonList, IonItem} from '@ionic/vue';

export default {
  name: 'Impressum',
  components: {IonTitle, IonContent, IonPage, IonGrid, IonRow, IonCol, IonList, IonItem}
}
</script>

<style lang="scss" scoped>
.lgtron-image {
  height: 100%;
  min-width: 20rem;
  object-fit: cover;
}

</style>